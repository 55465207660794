<template>
    <div class="footer">
        <ul class="footer__navigation">
            <li><router-link to="/disclaimer/">Algemene voorwaarden</router-link></li>
            <li><router-link to="/privacy/">Cookie</router-link></li>
        </ul>
        <div class="footer__copyright">Copyright &copy; {{ new Date().getFullYear() }}</div>
    </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
	name: 'Footer',
})
</script>