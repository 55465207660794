<template>
    <div class="row">
        <div v-for="(theme, index) in themes" :key="theme.id" class="col-12 col-lg-4 mb-5 mb-lg-0">
            <!-- TODO: block moet een complete state krijgen als hij is ingevuld -->
            <!-- give class "completed" to display as completed --> 
            <div class="block block--shadow block--theme">
                <span class="block__icon-circle"><span><icon icon="analyse"/></span></span>
                <h3 class="block__title">{{ theme.name }}</h3>


                <div class="block__toggle">
                    <div class="btn-toggle" :class="[theme.isActive ? 'active' : '']" @click="toggleClass(index)">
                        <span class="btn-toggle--name">toelichting<span><icon icon="chevron-down" class="fill-secondary" /></span></span>
                        <hr class="btn-toggle--separator">
                    </div>

                    <div class="block__ordered-list">
                        <div class="block__ordered-list__inner" :class="[theme.isActive ? 'show' : '']">
                            <!-- TODO: Title toevoegen, order list laten loopen als er meerdere lijsten zijn -->
                            <div v-html="theme.content"/>
                        </div>
                    </div>
                </div>

                <a class="btn btn-secondary" @click="clickTheme(theme)">
                    {{ button_label }}
                    <span><icon icon="arrow-right"/></span>
                </a>

                <div class="completed-dot">
                    <div class="" data-toggle="tooltip" data-placement="top" title="Tooltip on top">
                        <span><icon icon="check"/></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from "@/components/Icon";

    export default {
        name: 'Themes',
        components: {
            Icon
        },
        props: {
            btn_label: String
        },
        computed: {
            themes() {
                return this.$store.state.themes;
            },
            button_label() {
                if (typeof this.$props.btn_label !== 'undefined') {
                    return this.$props.btn_label;
                }

                return 'Volgende';
            }
        },
        mounted() {
            this.$store.dispatch('fetchThemes');
        },
        methods: {
            toggleClass(index) {
                this.themes[index].isActive = !this.themes[index].isActive;
            },
            clickTheme(theme) {
                this.$emit('click-theme', theme);
            }
        }
    }
</script>