import store from './../store';

export default class AspectModel {
    item = null;
    remarksText = null;
    fromLevel = null;
    toLevel = null;

    constructor(item) {
        if (typeof item !== 'undefined') {
            this.item = item;

            this.initLevels();
        }
    }

    initLevels() {
        // Set From / To from state;
        if (typeof this.getState() !== 'undefined' && typeof this.getState().from === 'object' && this.getState().from !== null) {
            this.levels.forEach((level) => {
                if (level.id === this.getState().from.id) {
                    level.isFrom = true;
                    this.fromLevel = level;
                }

                if (typeof this.getState().to === 'object' && this.getState().to !== null && level.id === this.getState().to.id) {
                    level.isTo = true;
                    this.toLevel = level;
                }
            });
        }
    }

    get id() {
        return this.item.id;
    }

    get title() {
        return this.item.title;
    }

    get slug() {
        return this.item.slug;
    }

    get levels() {
        return this.item.levels;
    }

    get remarks() {
        if (typeof this.getState() !== 'undefined' && typeof this.item.remarks === 'undefined') {
            this.item.remarks = this.getState().remarks
        } else {
            this.item.remarks = null;
        }

        return this.item.remarks;
    }
    set remarks(remarks) {
        this.item.remarks = remarks;

        store.commit('SET_ASPECT', this);
    }

    get from() {
        return this.fromLevel;
    }
    set from(from) {
        this.fromLevel = from
    }

    get to() {
        return this.toLevel;
    }
    set to(to) {
        this.toLevel = to;
    }

    get isChosen() {
        return typeof this.getState() !== 'undefined' && typeof this.getState().isChosen !== 'undefined' && this.getState().isChosen;
    }
    set isChosen(state) {
        this.item.isChosen = state;

        store.commit('SET_ASPECT', this);
    }

    get interventions() {
        return this.item.interventions;
    }

    get primaryIntervention() {
        if (typeof this.interventions !== 'object') {
            return null;
        }

        return this.interventions[Object.keys(this.interventions)[0]];
    }

    getState() {
        return store.getters.getStateByAspect(this.item);
    }

    resetLevels() {
        this.from = null;
        this.to = null;

        this.levels.forEach((level) => {
            level.isFrom = false;
            level.isTo = false;
        });

        store.commit('SET_ASPECT', this);
    }

    isActionItemChosen(actionItemToCheck) {
        const key = `aspect_${this.id}_${store.state.userRole}`;
        if (typeof store.state.aspects[key] === 'undefined' || typeof store.state.aspects[key].actionItems === 'undefined') {
            return false;
        }

        for (const actionItemKey in store.state.aspects[key].actionItems) {
            const actionItem = store.state.aspects[key].actionItems[actionItemKey];
            if (actionItem === actionItemToCheck) {
                return actionItemKey;
            }
        }

        return false;
    }

    getChosenActionItems() {
        const key = `aspect_${this.id}_${store.state.userRole}`;
        if (typeof store.state.aspects[key] === 'undefined' || typeof store.state.aspects[key].actionItems === 'undefined') {
            return [];
        }

        return store.state.aspects[key].actionItems;
    }

    setActionActionItem(actionItem) {
        const aspect = this;
        store.commit('SET_ASPECT_ACTIONITEM', {aspect, actionItem});
    }

    unsetActionActionItem(actionItem) {
        const aspect = this;
        store.commit('UNSET_ASPECT_ACTIONITEM', {aspect, actionItem});
    }
}