<template>
    <div id="modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 id="modalTitle" class="modal-title">
                        {{ title }}
                    </h2>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        sluiten
                        <span><icon icon="times"/></span>
                    </button>
                </div>
                <div class="modal-body">
                    <slot/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import jQuery from 'jquery';
    import 'bootstrap';

    import Icon from "./Icon";

    export default {
        name: 'Modal',
        components: {
            Icon
        },
        props: {
            title: String,
        },
        methods: {
            openModal() {
                jQuery('#modal').modal('show');
            },
            closeModal() {
                jQuery('#modal').modal('hide');
            },
        },
    }
</script>
