<template>
    <a href="#" class="btn btn-primary" @click.prevent="print()">Afdrukken<span><icon icon="print"/></span></a>
</template>

<script>
    import Icon from "@/components/Icon";

    export default {
        name: 'PrintButton',
        components: {
            Icon
        },
        methods: {
            print() {
                window.print();
            }
        },
    }
</script>