<template>
    <div class="video-wrapper">
        <div class="embed-responsive embed-responsive-16by9" v-html="embed"/>
    </div>
</template>

<script>
    export default {
        name: 'Home',
        components: {

        },
        props: {
            embed: String,
        }
    }
</script>
