<template>
    <div class="dashboard">
        <h2 class="text-secondary">{{ page.title }}</h2>

        <div class="row mb-5">
            <div class="col-12 col-lg-7 order-1 order-lg-0">
                <div class="cof-content">
                    <div v-html="page.content"/>
                </div>
            </div>
            <div class="col-12 col-lg-5 order-0 order-lg-1">
                <div class="mb-4">
                    <Video v-if="page.videoEmbed" :embed="page.videoEmbed"/>
                </div>
                <div class="text-right">
                    <div>
                        <router-link to="/over/" class="btn btn-outline-secondary">
                            Over de leeromgeving
                            <span class="mr-0 ml-2"><icon icon="arrow-right"/></span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <!-- Dashboard list -->
        <Themes @click-theme="chooseTheme"/>
    </div>
</template>

<script>
import api from '../data/api'
import Themes from '@/components/Themes.vue';
import Video from '@/components/Video.vue';
import Icon from "@/components/Icon";

export default {
	name: 'Home',
	components: {
		Themes,
		Video,
		Icon
	},
	data() {
		return {
			pageTitle: 'Dashboard',
			page: {},
		}
	},
	mounted() {
		api.fetchPage(6).then(response => {
			this.page = response;
		});

		const breadcrumbs = [{label: 'Home', path: '/'}];
		this.$store.commit('SET_BREADCRUMBS', {breadcrumbs});
	},
	methods: {
		chooseTheme(theme) {
			const _this = this;

			this.emitter.emit('choose-role', {
				theme: theme,
				success() {
					_this.$router.push({path: `/analyse/${theme.slug}`});
				}
			});
		}
	},
}
</script>
