import {createStore} from 'vuex'
import api from '../data/api'

export default createStore({

    state: {
        userRole: null,
        themes: [],
        roles: [],
        aspects: {},
        breadcrumbs: [],
        breadcrumbs_buttons: {}
    },
    mutations: {
        initialiseStore(state) {
            // Check if the ID exists
            if (window.localStorage.getItem('store')) {
                // Replace the state object with the stored item
                this.replaceState(
                    Object.assign(state, JSON.parse(window.localStorage.getItem('store')))
                );
            }
        },
        SET_ASPECT_BY_ROLE_AND_THEME(state, aspectByRoleAndTheme){
            state.aspectByRoleAndTheme = aspectByRoleAndTheme;
        },
        SET_THEMES(state, themes) {
            state.themes = themes
        },
        SET_ROLES(state, roles) {
            state.roles = roles
        },
        SET_HOMEPAGE_CONTENT(state, content) {
            state.homepageContent = content
        },
        SET_USER_ROLE(state, userRole) {
            state.userRole = userRole
        },
        SET_ASPECT(state, aspect) {
            const key = `aspect_${aspect.id}_${state.userRole}`;
            if (typeof state.aspects[key] === 'undefined') {
                state.aspects[key] = {}
            }

            state.aspects[key] = {from: aspect.from, to: aspect.to, remarks: aspect.item.remarks, isChosen: aspect.item.isChosen};
        },
        SET_ASPECT_ACTIONITEM(state, {aspect, actionItem}) {
            const key = `aspect_${aspect.id}_${state.userRole}`;
            if (typeof state.aspects[key] === 'undefined') {
                state.aspects[key] = {};
            }

            if (typeof state.aspects[key].actionItems === 'undefined') {
                state.aspects[key].actionItems = [];
            }

            if (!aspect.isActionItemChosen(actionItem.text)) {
                state.aspects[key].actionItems.push(actionItem.text);
            }
        },
        UNSET_ASPECT_ACTIONITEM(state, {aspect, actionItem}) {
            const key = `aspect_${aspect.id}_${state.userRole}`;
            if (typeof state.aspects[key] === 'undefined') {
                return;
            }

            const isActionItemChosen = aspect.isActionItemChosen(actionItem.text);
            if (isActionItemChosen !== false) {
                state.aspects[key].actionItems.splice(isActionItemChosen, 1);
            }
        },
        SET_BREADCRUMBS(state, {breadcrumbs}) {
            state.breadcrumbs = breadcrumbs;
        },
        SET_BREADCRUMBS_BUTTONS(state, setting) {
            state.breadcrumbs_buttons = setting;
        },
        CLEAR_BREADCRUMBS(state) {
            state.breadcrumbs = [];
            state.breadcrumbs_buttons = {};
        }
    },
    actions: {
        fetchThemes({commit}) {
            api.fetchThemes().then(response => {
                commit('SET_THEMES', response)
            });
        },
        fetchRoles({commit}) {
            api.fetchRoles().then(response => {
                commit('SET_ROLES', response)
            });
        },
        fetchHomepage({commit}) {
            api.fetchPage(6).then(response => {
                commit('SET_HOMEPAGE_CONTENT', response)
            });
        },
    },
    getters: {
        getThemeBySlug: (state) => (slug) => {

            state.themes.forEach((theme) => {
                if (theme.slug === slug) {
                    return theme;
                }
                return false;
            });

            let currentTheme = null;

            state.themes.forEach((theme) => {
                if (theme.slug === slug) {
                    currentTheme = theme;
                    return;
                }
            });

            return currentTheme;
        },
        getCurrentRole: (state) => () => {

            if (state.userRole == null) {
                return false;
            }

            let currentRole = null;

            state.roles.forEach((role) => {
                if (role.id === state.userRole) {
                    currentRole = role;
                    return ;
                }
                return false;
            });

            return currentRole;
        },
        getStateByAspect: (state) => (aspect) => {
            const key = `aspect_${aspect.id}_${state.userRole}`;

            return state.aspects[key];
        }
    },
    modules: {}
})
